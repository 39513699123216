<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2" @click="informationernaehrungzuinformation()">
            <img src="../assets/icons/vector_back.svg">
            <!-- wenn kein Icon, img entfernen -->
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Ernährung</p>
          </div>
          <div class="col-2 col-right">
            <!-- wenn kein Icon, img entfernen -->
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-body margintop140 container marginbottom20">
      <p class="pregular fontcolor-white opacity80">Hilfreiche Tipps</p>
    </div>

    <div class="container paddingbottom110">
      <div class="accordion" role="tablist">
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">
              <p class="psemibold">
                Eine grundlegende Empfehlung
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Nach der Transplantation sollte darauf geachtet werden, dass man nicht zu stark an Gewicht zunimmt. Da die Einnahme von Cortison zu vermehrtem Appetit führt, sollte die Kalorienzufuhr eingeschränkt werden. Grundsätzlich ist jedoch keine strenge Diät einzuhalten. Eine gesunde, ausgewogene Kost wird empfohlen.
              </p>
            </b-card-body>
          </b-collapse>
          <div></div>
        </b-card>
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">
              <p class="psemibold">
                Wichtige Hinweise zur Ernährung
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                -	Kein Grapefruitsaft, aufgrund der Unverträglichkeit mit den Medikamenten Prograf® und Sandimmun®. <br>
                -	Obst und Gemüse vor dem Verzehr sehr gründlich säubern und waschen. Obst mit faulen Stellen wegwerfen.<br>
                -	Wegen der Möglichkeit des Schimmelbefalls sollten Sie auf Müsli, Cornflakes und Nüsse verzichten. <br>
                -	Pilze sind verboten, da sie Erdkeime übertragen könnten. Ausnahme: Zuchtpilze (z.B. Champignons). <br>
                -	Keinen Schimmelkäse und Rohmilchkäse essen. Ansonsten sind alle Käsesorten zum Verzehr geeignet.<br>
                -	Keine Rohmilch und Joghurt mit Lebensbakterien. Ansonsten können alle Milchprodukte verwendet werden. <br>
                -	Abgepacktes Eis kaufen. Eis vom Eisstand ist aufgrund des Salmonellenrisikos nicht zu empfehlen. <br>
                -	Weder rohes Fleisch, rohen Fisch, noch rohe Eier essen (Sushi, Tiramisu, Mayonnaise usw.)<br>

              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">
              <p class="psemibold">
                Hilfreiche Tipps beim Einkaufen
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                -	Haltbarkeitsdatum beachten <br>
                -	Leicht verderbliche Lebensmittel nicht auf Vorrat kaufen <br>
                -	Für Tiefkühlkost eine Isoliertasche benutzen <br>
                -	Keine Großpackungen kaufen, da angebrochene Packungen schnellstmöglich verbraucht werden sollten <br>

              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">
              <p class="psemibold">
                Richtige Lagerung der Lebensmittel
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                -	Frischwaren in Behälter umpacken und kühl lagern <br>
                -	Eier immer in den Kühlschrank<br>
                -	Schimmelpilze können vermieden werden, indem Sie Brotkasten und Kühlschrank regelmäßig mit Essigwasser auswaschen<br>
                -	Beschädigte Konserven und solche mit vorgewölbtem Deckel wegwerfen<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">
              <p class="psemibold">
                Sauberkeit bei der Zubereitung
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                -	Arbeitsgeräte und Arbeitsflächen sauber halten (Kunststoff ist hygienischer als Holz) <br>
                -	Spültücher und –bürsten regelmäßig wechseln<br>
                -	Langes Warmhalten von Speisen vermeiden<br>
                -	Essgeschirr und Näpfe von Tieren getrennt reinigen<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- <information-accordion accordiontitle="Vitalwerte" accordiontext="Loremipsumdolorsit"></information-accordion> -->
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    components: {
      'foot-navigation' :FootNavigationVue,
    },
    methods: {
      informationernaehrungzuinformation() {
        router.push({ path: '/information' })
      },
    },
   }; 
</script>

<style>
.accordion {
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    text-align: left;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem!important;
}

.p-1 {
    padding: 0rem!important;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-radius: 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card-header:first-child {
    border-radius: 0 !important;
}

.card-header {
    padding: 0.75rem 0rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.btn {
    background: transparent;
    border: 0;
    outline: none;
    border-radius: 30px;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.3rem 0rem;
    font-size: 1rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-info {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-info.focus, .btn-info:focus {
    box-shadow: none !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0rem 1.5rem 0rem;
}

</style>